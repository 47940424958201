import '@fontsource/taviraj';
import '@fontsource/dawning-of-a-new-day';
import React from 'react';
import './src/styles/global.css';

import { AppProviders } from './src/components/app-providers';

/**
 * @param {import('gatsby').WrapRootElementBrowserArgs} args
 */
export function wrapRootElement({ element }) {
	return <AppProviders>{element}</AppProviders>;
}
